/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                
                // JavaScript to be fired on all pages

                document.getElementById("search_page_button").addEventListener("click", function () {
					document.getElementById("search_page_form").submit();
                });
                $('.tile').each(function () {
                    // $(this).on('click', function (event) {
                        // if (($(this).data('link') != '') && ($(this).data('link') != undefined) && ($(this).data('link') != 'undefined')) {
                            // location.href = $(this).data('link');
                        // }
                    // });
                });

                // Jump to *******************************************
                $('.jumpto').click(function(){
                    scrollTo($($(this).data('target')));
                });
				
				var $cont = $('.collapse-container');
				$('.navbar-toggle').click(function() {
					$cont.animate({width: '300px'}, 500, function() {
						$cont.addClass('open').css('width', '');
					});
				});
				
				$cont.find('.close-icon i').click(function() {
					$cont.animate({width: '0px'}, 500, function() {
						$cont.removeClass('open').css('width', '');
					});
				});
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
                // $('#searchbackground').on('show.bs.modal', function() {
                    // console.log('ye');
                // });
                $('#searchbackground').modal({
                    show: false,
                    keyboard: true, 
                    backdrop: 'static'
                }).on('show.bs.modal', function() {
                    $(this).one('bsTransitionEnd', function() {
                        $(this).find('.search-field').focus(); 
                    });
                });
                
                $('.top-search-icon').click(function() {
                    $('#searchbackground').modal('show');
                });
                
                
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        // Karte
        'karte': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        },
		'page_template_glossar': {
            init: function() {
				var $topArrow = $('.to-top-arrow.fixed');
				$(window).on('scroll', function() {
					var scrollPosition = window.pageYOffset;
					var windowSize     = window.innerHeight;
					var bodyHeight     = document.body.offsetHeight;
					var scrollFromBottom = Math.max(bodyHeight - (scrollPosition + windowSize), 0);
					if (window.pageYOffset == 0 || (window.pageYOffset + window.innerHeight < $('#a').offset().top) || scrollFromBottom < $('footer').height()) {
						$topArrow.addClass('hidden');
					}
					else {
						$topArrow.removeClass('hidden');
					}
				});
                // JavaScript to be fired on the glossar page
            }
        },
		'page_template_suche': {
            init: function() {
				$('#such-container .row.show-more').click(function() {
					var $this = $(this);
					$this.find('span').remove();
					$this.find('.loader').removeClass('hidden');
					setTimeout(function() { $this.remove(); }, 1000);
				});
                // JavaScript to be fired on the search page
            }
        },
		
        // Kontaktlinsen-Test
        'page_template_kontaktlinsentest': {

            init: function () {
                Sage['page_template_kontaktlinsentest'].initTest();

                //$('.kontaktlinsentest-start').click(function(){
                //    Sage['page_template_kontaktlinsentest'].initTest();
                //});
                $('.kontaktlinsentest-start').click(function(){
                    Sage['page_template_kontaktlinsentest'].initTest($(this).data('type'));
                });
            },
            initTest: function(type){

                type = typeof type !== 'undefined' ? type : '';

                if(type){
                    $('#kontaktlinsen-test-result').slideUp('slow', function(){
                        $('#kontaktlinsen-test').slideDown('slow', function() {
				            UTIL.scrollTo($('.kontaktlinsen-test-wrapper'), 1);
						});
                    });
                }
				$('#kontaktlinsen-test .last-question').addClass('hidden');
                // Test Default vars
                answers             = [0, 0, 0];
                totalQuestions      = $('#kontaktlinsen-test .question').length;
                currentQuestion     = 1;

                //console.log('init:' + currentQuestion);
                //console.log('init:' + totalQuestions);

                // Test init
                $('#kltest-q1').addClass('active');
                $('.status-inner').width(currentQuestion/totalQuestions*100 + '%');
                $('.kltest-status .current').text(currentQuestion);
                $('.kltest-status .total').text(totalQuestions);
                setTimeout(function(){
                    $('#kontaktlinsen-test-result .result-item').addClass('hidden');
                },1000);

                if(type == ''){
                    // Test set answer
					// back button
					$('#kontaktlinsen-test .last-question').click(function() {
						var $answer = $('#kontaktlinsen-test').data('last_answer');
						answers[$answer]--;
						$('#kltest-q' + (currentQuestion)).removeClass('active');
						currentQuestion--;
						if (currentQuestion == 1) {
							$('.last-question').addClass('hidden');
						}
						$('#kltest-q' + (currentQuestion)).addClass('active');
                        $('.kltest-status .current').text(currentQuestion);
                        $('.status-inner').width(currentQuestion/totalQuestions*100 + '%');
					});
					
					// click any answer button
                    $('#kontaktlinsen-test .answer').click(function () {
						var $answer = $(this).data('answer');
						$('#kontaktlinsen-test').data('last_answer', $answer);
                        $('#kltest-q' + (currentQuestion)).removeClass('active');
                        answers[$answer]++;
                        currentQuestion++;
                        // Next question
                        if(currentQuestion <= totalQuestions){
														$('.last-question').removeClass('hidden');
                            $('#kltest-q' + (currentQuestion)).addClass('active');
                            $('.kltest-status .current').text(currentQuestion);

                            $('.status-inner').width(currentQuestion/totalQuestions*100 + '%');
                        // Result
                        }else{
                            var maxValue        = answers[0];
                            var maxPosition     = 0;
                            var sameValue       = false;
                            for (var i = 1; i < answers.length; i++) {
                                if (answers[i] == maxValue) {
                                    sameValue = answers[i];
                                }
                                if (answers[i] > maxValue) {
                                    maxValue    = answers[i];
                                    maxPosition = i;
                                }
                            }
                            if(sameValue !== maxValue){
                                sameValue = false;
                            }

                            console.log('max value:' + maxValue);
                            console.log('max pos:' + maxPosition);
                            console.log('same value:' + sameValue);

                            var resultPage = '';
                            if(sameValue){
                                resultPage = 'entwederoder';
                            }else if(maxPosition == 0){
                                resultPage = 'tageslinsen';
                            }else if(maxPosition == 1){
                                resultPage = '14tageslinsen';
                            }else if(maxPosition == 2){
                                resultPage = 'monatslinsen';
                            }else if(maxPosition == 3){
                            		resultPage = 'nichteindeutig';
                            }

                            $('#kontaktlinsen-test-result-' + resultPage).removeClass('hidden');

                            $('#kontaktlinsen-test').slideUp('slow', function(){
                                $('#kontaktlinsen-test-result').slideDown('slow', function() {
                                    UTIL.scrollTo($('.kontaktlinsen-test-wrapper'), 1);
                                });
                            });
                        }
                    });
                }
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        },
        scrollTo: function(elem, offset){
            var defaultOffset = -20;
            offset = offset || defaultOffset;
            $('body,html').animate({
                scrollTop: elem.offset().top+offset
            });
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.